import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { DividerBlock } from '../components/dividerBlock';
import { ContentImage } from '../components/content/image';
import { ContentVideo } from '../components/content/video';

const About = ({ data }) => {
  const { about } = data;

  const buildContent = (obj) => {
    const type = obj.model.name;
    if (type === 'Image') {
      return <ContentImage key={obj.id} image={obj.image} caption={obj.workCaption} />;
    }
    if (type === 'Video') {
      return <ContentVideo key={obj.id} video={obj.video.video.mp4Url} caption={obj.workCaption} />;
    }
    if (type === 'Text and Image') {
      return (
        <div className="about-section row" key={obj.id}>
          <div className="col-sm-7 offset-sm-2 col-xs-6 col">
            <h2 className="gothic--large large-title">{obj.heading}</h2>
          </div>
          <div className="col-sm-7 offset-sm-2 col-xs-6 col">
            {obj.image && (
            <div className="about-image">
              <Img fluid={{ ...obj.image.fluid }} alt={obj.image.alt} />
              {obj.image.title && (
                <p
                  className="caption"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: obj.image.title,
                  }}
                />
              )}
            </div>
            )}
            <div
              className="content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: obj.description,
              }}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Layout bgcolor={about.backgroundColor} wrapperClass="about" accentColor={about.accentColor}>
      <HelmetDatoCms seo={about.seoMetaTags} />
      <div className="about-intro container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{about.title}</h1>
          {about.headerImage && (
            <>
              <Img fluid={{ ...about.headerImage.fluid }} className="about-image" alt={about.headerImage.alt} />
              {about.headerImage.title && <p className="caption">{about.headerImage.title}</p>}
            </>
          )}
          <div
            className="content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: about.description,
            }}
          />
        </div>
      </div>
      <div className="about-content container row">
        {about.content.length > 0
          && about.content.map((content) => (
            buildContent(content)
          ))}
      </div>
      <div className="container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={about.accentColor} />
        </div>
      </div>
    </Layout>
  );
};

About.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default About;

export const query = graphql`
  query AboutQuery {
    about: datoCmsAbout {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      accentColor {
        hex
      }
      backgroundColor
      description
      headerImage {
        alt
        title
        fluid(maxHeight: 540) {
          ...GatsbyDatoCmsSizes_noBase64
        }
      }
      title
      content {
        ... on DatoCmsImage {
          id
          model {
            name
          }
          image {
            title
            alt
            fluid(maxHeight: 500) {
              ...GatsbyDatoCmsSizes_noBase64
            }
          }
          workCaption
        }
        ... on DatoCmsVideo {
          id
          model {
            name
          }
          video {
            video {
              mp4Url
            }
          }
          workCaption
        }
        ... on DatoCmsTextAndImage {
          id
          model {
            name
          }
          image {
            alt
            title
            fluid(maxHeight: 540) {
              ...GatsbyDatoCmsSizes_noBase64
            }
          }
          description
          heading
        }
      }
    }
  }
`;
